import makeStyles from '@mui/styles/makeStyles'

export default makeStyles(theme => ({
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.elements.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  primaryLight: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.elements.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  secondaryLight: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  success: {
    backgroundColor: theme.palette.highlight.lightGreen,
    color: theme.palette.highlight.green,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.success.main
    }
  },
  successLight: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.elements.white,
    '&:hover': {
      backgroundColor: theme.palette.error.main
    }
  },
  errorLight: {
    backgroundColor: theme.palette.error.extraLight,
    color: theme.palette.error.main
  },
  warning: {
    backgroundColor: theme.palette.highlight.lightYellow,
    color: theme.palette.highlight.yellow,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.warning.main
    }
  },
  warningLight: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main
  },
  disabled: {
    backgroundColor: theme.palette.text.disabled,
    color: theme.palette.elements.white
  },

  disabledOutlined: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.other.disabled}`,
    color: theme.palette.other.disabled,
    '&:hover': {
      color: 'white'
    }
  },
  helpLight: {
    backgroundColor: theme.palette.help.xtraLight,
    color: theme.palette.help.main,
    fontWeight: 'bold'
  }
}))
